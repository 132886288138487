.maidSdateTable {
text-align: left;
padding-left: 2px;
}
.maidAllTable {
    text-align: left;
 }
.maidTrainerTable {
    text-align: left;
 }
 .maidTierAver{
    text-align: left;
 }
 .ApproveTraining{
    text-align: left;
 }
 .ApproveStatus{
    text-align: left;
    width: 100%;
    cursor: pointer;
 }

 .paymentStatus{
    text-align: left;
    width: 100%;
    cursor: pointer;
    height: 25px;
}
.paymentSOTHours{
    text-align: left;
    padding-left: 10px;
}

.maidListetable .rt-tbody {
   min-height: 718px;
   max-height: 718px;
 }
 @media screen and (max-width: 1366px) and (max-height: 768px)  {
   .maidListetable .rt-tbody {
      min-height: 430px;
      max-height: 430px;
    }
    .maidSdateTable {
        padding-left: 12px;
    }
    .maidAllTable {
        padding-left: 12px;
     }
     .maidTrainerTable {
        padding-left: 15px;
     }
     .maidTierAver{
        padding-left: 5px;
     }
     .ApproveTraining{
        padding-left: 10px;
     }
     .ApproveStatus{
        padding-left: 10px;
     }
     .paymentStatus{
        padding-left: 10px;
    }
    .paymentSOTHours{
        padding-left: 5px;
    }
  }
  @media screen and (max-width: 720px) and (max-height: 768px)  {
    .maidSdateTable {
        padding-left: 0px;
    }
    .maidAllTable {
        padding-left: 0px;
     }
     .maidTrainerTable {
        padding-left: 0px;
     }
     .maidTierAver{
        padding-left: 0px;
     }
     .ApproveTraining{
        padding-left: 10px;
     }
     .ApproveStatus{
        padding-left: 10px;
     }
     .paymentStatus{
        padding-left: 10px;
    }
    .paymentSOTHours{
        padding-left: 5px;
    }
  }
  @media screen and (min-width: 300) and (max-width: 530px) {
   
     .ApproveTraining{
        padding-left: 0px;
     }
     .ApproveStatus{
        padding-left: 0px;
     }
     .paymentStatus{
        padding-left: 0px;
    }
    .paymentSOTHours{
        padding-left: 0px;
    }
  }
 