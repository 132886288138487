.bookingLsittetable .rt-tbody {
    min-height: 665px;
    max-height: 665px;
  }

@media screen and (max-width: 1366px) and (max-height: 768px)  {
    .bookingLsittetable .rt-tbody {
        min-height: 378px;
        max-height: 378px;
      }
   }