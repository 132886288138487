.page-container {
  display: flex;
  flex-direction: column;
}
#main-wrapper[data-layout="vertical"] .topbar .top-navbar .navbar-header[data-logobg="skin6"],
#main-wrapper[data-layout="horizontal"] .topbar .top-navbar .navbar-header[data-logobg="skin6"] {
  background: #6e8ca0;
}
#main-wrapper[data-layout="vertical"] .topbar .navbar-collapse[data-navbarbg="skin6"],
#main-wrapper[data-layout="vertical"] .topbar[data-navbarbg="skin6"],
#main-wrapper[data-layout="horizontal"] .topbar .navbar-collapse[data-navbarbg="skin6"],
#main-wrapper[data-layout="horizontal"] .topbar[data-navbarbg="skin6"] {
  background: #6e8ca0;
}

.wrapper {
  display: flex;
  align-items: center;
  background-color: gainsboro !important;
  /* margin-top: 80px;
  margin-bottom: 80px; */
  height: 100vh;
  width: 100vw;
  /* background-color: red; */
}

.login-button {
  background-color: #4798e8;
  width: 100%;
  height: 50px;
  border: none;
  color: white;
}
.login-button:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}
.login-button:disabled,
.login-button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.form-signin {
  width: 80%;
  max-width: 380px;
  padding: 15px 35px 25px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  margin-bottom: 20px;
}
.headering {
  margin-bottom: 30px;
}
.focus {
  z-index: 2;
}
.page-wrapper {
  background: #f0f3f4;
  display: flex;
  height: 100vh;
}

.page-breadcrumb .btn-container {
  display: flex;
  margin-bottom: 15px;
}

/* Customize Button */
.btn-container .btn {
  margin: 0 10px 0 0;
}

.btn-primary {
  color: #fff;
  background-color: #6e8ca0;
  border-color: #6e8ca0;
}
.btn-primary:hover {
  color: #fff;
  background-color: #324755;
  border-color: #293a46;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(140, 180, 218, 0.5);
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #293a46;
  border-color: #293a46;
}
.btn-rounded {
  border-radius: 6px;
  padding: 7px 18px;
}
/* =================================*/
.m-l-5 {
  margin-left: 5px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-l-25 {
  margin-left: 25px;
}

.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-r-20 {
  margin-right: 20px;
}
.m-r-25 {
  margin-right: 25px;
}

.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-25 {
  margin-bottom: 25px;
}

.top-bg {
  background: #6e8ca0;
}
.copyClipBoard:hover {
  border: 1px red solid;
}

.logo-text {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  color: white;
}

.font-white {
  color: #fff;
}

.keep-word {
  word-break: keep-all;
}

.page-title {
  display: flex;
  width: 100%;
  padding: 0px 0px 0px 20px;
}

.user-profile .user-pic img {
  width: 100px;
}

#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin3"],
#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin3"] ul,
#main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin3"],
#main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin3"] ul {
  background: #17a991;
}

/* ###### Side bar modifier ###### */
/* No Border on Left sidebar*/
.left-sidebar {
  border-right: 0px;
}

/* First level selected HighLight*/
li.sidebar-item a.sidebar-link.has-arrow.active {
  background: #6e8ca0;
  color: white;
}

/* Sidebar: Second level focus background*/
li.sidebar-item ul.in li.sidebar-item {
  background: #f0f3f4;
}

/* Sidebar: Second level - active */
li.sidebar-item li.sidebar-item a.sidebar-link.active {
  color: black;
}

/* Sidebar: Second level - inactive */
li.sidebar-item li.sidebar-item a.sidebar-link {
  color: rgba(50, 71, 85, 0.5);
  /* color:#324755; */
}
/* .page-title {
  display: flex;
  width: 100%;
  padding: 10px 10px 0px 20px;
}
.topbar {
  height: 40px;
  background: #333d54 !important;
}
.left-sidebar {
  position: absolute !important;
  width: 250px;
  height: 100%;
  top: 0;
  border-right: 1px solid rgba(0,0,0,.1);
  z-index: 10;
  padding-top: 64px;
  background: #333d54 !important;
} */

/* Fix non focus bar */
.sidebar-nav ul .sidebar-item .sidebar-link {
  opacity: 1;
}

/* Hide icon when Sidebar is full  */
/* Show icon when Sidebar is mini-sidebar  */
#main-wrapper[data-layout="vertical"][data-sidebar-position="fixed"][data-sidebartype="full"]
  .sidebar-nav
  .icon {
  display: none;
}
#main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"] .sidebar-nav .icon {
  display: block;
}

/* Hide User-name in profile pane, when Sidebar is mini-sidebar */
#main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"] .user-profile .user-name {
  display: none;
}

/* Show User-name in profile pane,*/
/* When id=topbar-expand has className='navbar-header expand-logo' */
/* 190918 this will use jquery integrate react */
/* #main-wrapper[data-layout='vertical'][data-sidebartype='mini-sidebar'] #topbar-expand[className='navbar-header expand-logo'] .user-name{
    display: block;
    } */

/* ###### Side bar modifier (END) ###### */

/* ###### Card #####*/
.h-100 {
  height: 100%;
}
.h-vh-80 {
  height: 80vh;
}

.card-location {
  /* padding: 0 3vw 0 5vw; */
  width: 100%;
  height: 285px;
  justify-content: center;
  align-items: center;
  /* background: #eeeeee; */
}
.d-grid {
  display: grid;
  width: 220px;
  grid-gap: 10px;
}
.g-col-100 {
  align-self: center;
  grid-template-columns: repeat(auto-fill, minmax(100px, 100px));
}

.d-grid .d-grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Custom Checkbox*/
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #324755;
  background-color: #324755;
}

/* Custom Customizer */
.customizer {
  top: 64px;
  background-color: #6e8ca0;
}

.rightbar {
  /* background: #fff; */
  background-color: #6e8ca0; /*Adjust from template*/
  position: fixed;
  height: 100%;
  width: 280px;
  top: 40px; /*Adjust from template*/
  right: -280px;
  z-index: 100;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  transition: 0.3s ease-in;
}
.rightbar .service-panel-toggle {
  background: #ef6e6e;
  padding: 12px 20px;
  color: #fff;
  position: absolute;
  top: 30%;
  left: -54px;
}
.rightbar.show-service-panel {
  right: 0px;
}
.rightbar .rightbar-body {
  position: relative;
  height: 100%;
}
.rightbar .rightbar-tab {
  display: flex;
}
.rightbar .rightbar-tab .nav-item {
  width: 33.33%;
  text-align: center;
}
.rightbar .rightbar-tab .nav-item .nav-link {
  padding: 15px 12px;
  color: #3e5569;
  border-bottom: 3px solid transparent;
}
.rightbar .rightbar-tab .nav-item .nav-link:hover,
.customizer .customizer-tab .nav-item .nav-link.active {
  border-bottom: 3px solid #4798e8;
  color: #4798e8;
}

/* Fix High Chart Exceed width when first load*/
.highcharts-container {
  width: 100% !important;
}
.highcharts-root {
  width: 100% !important;
}
