.rightBarCustomer{
    height: 100vh;
  }
  @media screen and (max-width: 1680px) {
  }
 
  @media screen and (max-width: 1366px) and (max-height: 768px)  {
    .rightBarCustomer{
      height: 83vh;
      overflow: scroll;
    }

  }
   @media screen and (max-width: 1250px) and (max-height: 768px) {
    .rightBarCustomer{
      height: 82vh;
    }
  }
  @media screen and (min-width: 400px) and (max-height: 600px) {
    .rightBarCustomer{
      height: 75vh;
    }
}

 