.selectdate{
    width: '528px' !important;
    height: '36px'!important;
    border-radius: '5px'!important;
    border: 'solid 1px #707070'!important
}

.Rectangle-Datepicker-OrderPymentL {
    width: 250px;
    height: 34px;
    border-left-width:0 !important;
    border-radius: 1px;
    border: solid 1px #324755;
    background-color: #ffffff;
    text-align: center
  }
  .Rectangle-Datepicker-OrderPymentR {
    width: 250px;
    height: 34px;
    /* border-right-width:0 !important; */
    border-radius: 1px;
    border: solid 1px #324755;
    background-color: #ffffff;
    text-align: center
  }
  .rightBarOrdermangement{
    height: 88vh;
    overflow: scroll;
  }
  .transformIconRigbarFillter{
    transform: rotate(90deg);
  }
  @media screen and (max-width: 1366px) and (max-height: 768px)  {
    .rightBarOrdermangement{
      height: 83vh;
    }

  }
   @media screen and (max-width: 1250px) and (max-height: 768px) {
    .rightBarOrdermangement{
      height: 82vh;
    }
  }
  @media screen and (min-width: 400px) and (max-height: 600px) {
    .rightBarOrdermangement{
      height: 75vh;
    }
}