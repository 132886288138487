.approveLsittetable .rt-tbody {
    min-height: 680px;
    max-height: 680px;
    width: 100%;
  }

@media screen and (max-width: 1366px) and (max-height: 768px)  {
    .approveLsittetable .rt-tbody {
        min-height: 400px;
        margin-right: 400px;
      }
   }