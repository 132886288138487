/* .Rectangle-19 {
    width: 330px;
    height: 652px;
    border-radius: 6px;
    border: solid 1px #324755;
    background-color: #ffffff;
  } */
.OrderFilterInput {
  width: 200px; 
  height: 36px;
  border-radius: 5px;
  border: 1px solid rgb(112, 112, 112); 
  padding-left: 5px;
}

.OderReactTable {
  width: 650px;
  height: 400px;
  border-radius: 6px;
  border: 1px solid rgb(50, 71, 85);
  background-color: rgb(255, 255, 255);
}
.Datepicker_react_Popup{
  z-index: 9;
  width: 250px;
  min-width: 250px;
}
.Datepicker_react{
  height: 34px;
  border-radius: 1px;
  border: solid 1px #324755;
  background-color: #ffffff;
  padding-left:5px
}

.headerTable{
  text-align: center;
}
.maidTrainerTableProfile {
  padding-left: 15px;
  text-align: left;
}
.maidStarTableProfile {
  padding-left: 10px;
  text-align: left;
}
.cradProfileMiad{
  width: 290px !important;
  height: 100% !important;
  border-radius : 6px!important;
  border:  solid 1px #324755!important;
  background-color: #ffffff!important;
}

.maidDetailtetable .rt-tbody {
  min-height: 600px;
  max-height:600px ;
}

@media screen and (max-width: 1680px) {
  .OrderFilterInput {
    width: 180px; 
  }
  .OderReactTable {
    width: 650px;
  }
}
@media screen and (max-width: 1400px) {
  .OrderFilterInput {
    width: 160px; 
  }
  .OderReactTable {
    width: 630px;
  }
  
}

@media screen and (max-width: 1250px) {
  .OrderFilterInput {
    width: 150px; 
  }
  .OderReactTable {
    width: 600px;
  }
 
  
}
@media screen and (max-width: 1000px) {
  .OrderFilterInput {
    width: 130px; 
  }
  .OderReactTable {
    width: 500px;
  }
}
@media only screen and (max-width: 900px) {
  .OrderFilterInput {
    width: 100px; 
  }
  .OderReactTable {
    width: 450px;
  }
 
}

@media only screen and (max-width: 767px) {
  .OrderFilterInput {
    width: 200px; 
  }
  .cradProfileMiad{
    height: 90vh !important;
  }
}

@media screen and (max-width: 1366px) and (max-height: 768px)  {
  .maidTrainerTableProfile {
    padding-left: 17px;
 }
 .maidStarTableProfile {
  padding-left: 15px;
}
  .maidDetailtetable .rt-tbody {
      min-height: 350px;
      max-width: 350px;
    }
 }
 @media screen and (max-width: 720px) and (max-height: 768px)  {
   .maidTrainerTableProfile {
      padding-left: 0px;
   }
   .maidStarTableProfile {
    padding-left: 0px;
  }

  }
