.message-item:hover {
    background-color: aliceblue !important;
}

.active {
    background-color: #c0dbec;
}

.mailbox .chat-scroll {
    height: calc(100vh - 228px);
}

.mailbox .message-center .message-item {
    padding: 0px 15px;
}

.chat-list .chat-item .chat-img img {
    width: 65px;
    height: 65px;
}

.chat-list .chat-item .chat-content {
    padding-left: 30px;
}

/* 
.rounded-circle:before {
    content: ' ';
    display: block;
    position: absolute;
    height: 50px;
    width: 50px;
    background-image: url('../../images/preview-upload.png');
} */