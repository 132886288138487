/* .Rectangle-19 {
    width: 330px;
    height: 652px;
    border-radius: 6px;
    border: solid 1px #324755;
    background-color: #ffffff;
  } */
.OrderFilterInputPyment {
  width: 200px; 
  height: 30px;
  border-radius: 5px;
  border: 1px solid rgb(112, 112, 112); 
  padding-left: 5px;
}
.stylelabelPaymentProfile{
  font-size: 18px ;
  font-weight: "500px"
}
.OderReactTablePymentPyment {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid rgb(50, 71, 85);
  background-color: rgb(255, 255, 255);
}
.cradProfilePyment{
  width: 290px !important;
  height: 100% !important;
  border-radius : 6px!important;
  border:  solid 1px #324755!important;
  background-color: #ffffff!important
}

.paymentDetailtetable .rt-tbody {
  min-height: 671px;
  max-height: 671px;
}

@media screen and (max-width: 1680px) {
  .OrderFilterInputPyment {
    width: 160px; 
  }
  .OderReactTablePyment {
    width: 100%;
  }
}
@media screen and (max-width: 1400px) {
  .OrderFilterInputPyment {
    width: 115px; 
  }
  .OderReactTablePyment {
   width: 100%;
  }
}
@media screen and (max-width: 1250px) {
  .OrderFilterInputPyment {
    width: 120px; 
  }
  .OderReactTablePyment {
   width: 100%;
  }
  .stylelabelPaymentProfile{
    font-size: 15px;
  }
}
@media screen and (max-width: 1000px) {
  .OrderFilterInputPyment {
    width: 120px; 
  }
  .OderReactTablePyment {
   width: 100%;
  }
  .stylelabelPaymentProfile{
    font-size: 15px;
  }
}
@media only screen and (max-width: 900px) {
  .OrderFilterInputPyment {
    width: 100px; 
  }
  .OderReactTablePyment {
   width: 100%;
  }
  .stylelabelPaymentProfile{
    font-size: 11px;
  }
}
@media only screen and (max-width: 767px) {
  .OrderFilterInputPyment {
    width: 200px; 
  }
  .OderReactTablePyment {
   width: 100%;
  }
  .stylelabelPaymentProfile{
    font-size: 15px;
  }
  .cradProfilePyment{
    height: 90vh !important;
  }
}


@media screen and (max-width: 1366px) and (max-height: 768px)  {
  .paymentDetailtetable .rt-tbody {
      min-height: 350px;
      max-height: 350px;
    }
 }