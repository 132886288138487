.Datepicker_react_Popup_invenca_export{
    z-index: 9999;
  }
  .Datepicker_react_invenca_export{
    text-align: center;
    height: 34px;
    border-radius: 5px;
    border: solid 1px #324755;
    background-color: #ffffff;
    padding-left:5px;
    width: 250px;
    min-width: 250px;
  }

  .Datepicker_react_Popup_invenca{
    z-index: 9;
  }
  .Datepicker_react_invenca{
    text-align: center;
    height: 34px;
    border-radius: 5px;
    border: solid 1px #324755;
    background-color: #ffffff;
    padding-left:5px;
    width: 250px;
    min-width: 250px;
  }