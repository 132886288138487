
.Rectangle-710-promotion {
    width: 1002.4px;
    height: 654.3px;
    border-radius: 6px;
    border: solid 1px #324755;
    background-color: #ffffff;
    
  }

.Rectangle-22-promotion {
    width: 977.4px;
    height: 60px;
    border: solid 1px #324755;
    background-color: #ffffff;
    margin-top: 20px;
    margin-left: 10px;
  }
  .Rectangle-29-promotion {
    width: 977.4px;
    height: 70px;
    border: solid 1px #324755;
    background-color: #ffffff;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .Rectangle-30-promotion {
    width: 977.4px;
    height: 393px;
    border: solid 1px #324755;
    background-color: #ffffff;
    margin-left: 10px;
    margin-bottom: 30px;
  }
  .Name-of-Promotion {
    width: 261px;
    height: 35px;
    font-family: HelveticaNeue;
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: end;
    color: #324755;
  }
  .Rectangle-31-promotion {
    width: 639.9px;
    height: 34px;
    border-radius: 1px;
    border: solid 1px #324755;
    background-color: #ffffff;
  }
  .css-yk16xz-control{
    border: solid 1px #324755 !important;
  }
  .Rectangle-31-promotionSelect {
    width: 368px;
    height: 34px;
    border-radius: 1px;
    /* border: solid 1px #324755; */
    border-color: rgb(85, 22, 22);
    background-color: #ffffff;
  }
  .Rectangle-Datepicker-promotion {
    width: 368px;
    height: 34px;
    border-radius: 1px;
    border: solid 1px #324755;
    background-color: #ffffff;
  }
  .Rectangle-31Button-promotion {
    width: 200.2px;
    height: 34px;
    border-radius: 6px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.16);
    background-color: #6e8ca0;
  }
  .Generate-code-promotion {
    width: 120px;
    height: 21px;
    font-family: HelveticaNeue;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: #f0f3f4;
  }

  .Promotions-description-promotion {
    width: 182px;
    height: 17px;
    opacity: 0.5;
    font-family: HelveticaNeue;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: end;
    color: #324755;
  }
  .gentext-promotion{
    text-align: center;
  }

  @media  screen and (max-width: 450px) {
    .mainPage-Promotion-detail{
        left: 50%;
        top: 50%;
        overflow: scroll;
        position:inherit !important;
        transform:none !important
    }
    .Rectangle-30-promotion{
      overflow: scroll;
    }
    .gentext-promotion{
      text-align: left;
    }
  }
  @media  screen and (min-width: 800px) and (max-width: 1500px) {
    .mainPage-Promotion-detail{
        left: 50%;
        top: 50%;
        /* overflow: scroll; */
        position:inherit !important;
        transform:none !important;
      /* position: absolute; */
    }
  }
  @media  screen and (min-width: 1600px) {
    .mainPage-Promotion-detail{
      position: absolute;
      left: 50%;
      top: 50%;
      /* overflow: scroll; */
      position:inherit !important;
      transform:none !important
    }
  }

  .mainPage-Promotion-detail{
    position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
  }
  /* mainPage-Promotion-detail */
  

