.inPut-search-textMaild {
    padding: 6px;
    margin-top: 8px;
    font-size: 17px;
    border: none;
  }

.search-containe-button {
    float: right;
    padding: 6px 10px;
    margin-top: 8px;
    margin-right: 16px;
    background: white;
    font-size: 17px;
    border: none;
    cursor: pointer;
  }
  .search-container button:hover {
    background: #ccc;
  }
  .rightBarMaid{
    height: 100vh;
  }
  
  @media screen and (max-width: 1250px) {
    .inPut-search-textMaild {
      width: 220px !important;
    }
  }
  @media screen and (max-width: 1000px) {
    .inPut-search-textMaild {
      width: 180px !important;
    }
  }
  @media only screen and (max-width: 900px) {
    .inPut-search-textMaild {
      width: 150px !important;
    }
  }

  @media screen and (max-width: 1366px) and (max-height: 768px)  {
    .rightBarMaid{
      height: 83vh;
      overflow: scroll;
    }
  }
   @media screen and (max-width: 1250px) and (max-height: 768px) {
    .rightBarMaid{
      height: 82vh;
    }
  }
  @media screen and (min-width: 400px) and (max-height: 600px) {
    .rightBarMaid{
      height: 75vh;
    }
}