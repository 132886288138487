.container {
    position: relative;
    text-align: center;
    color: white;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 17em; 
    height:10em;
    border: "1px solid #000000";
    border-radius:"5px";
    word-wrap: break-word;
    background-color:#ffff;
    color:#000000
  }
  .centeredInApp{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 11em; 
    height:12em;
    font-size: 12px;
    border: "1px solid #000000";
    border-radius:"5px";
    word-wrap: break-word;
    /* background-color:#ffff; */
    color:#000000
  }
  .bottom-left {
    position: absolute;
    bottom: 0px;
    left: 16px;
    font-size: 12px;
  }
  .bottom-Top {
    position: absolute;
    Top: 30px;
    left: 16px;
    font-size: 12px;
  }
  .headerTop{
    width: 573px;
    position: absolute;
    top: -15px;
    left: -14px;
  }