.swal2-popup {
  height: 20em;
  width: 30em;
}

.swal2-actions {
  display: flex;
  justify-content: center;
}
.swal2-content {
  margin-top: 2rem;
}
.swal2-confirm {
  margin-top: 4rem;
  width: 200px;
  background-color: #6e8ca0 !important;
  color: white;
  padding: 10px !important;
  border-radius: 10px !important;
}
.swal2-close{
    top: -10px;
    right: -10px;
    border-radius: 30px;
    font-size: 24px;
    background-color: black;
    
}
.swal2-close:hover{
    top: -10px;
    right: -10px;
    border-radius: 30px;
    font-size: 24px;
    background-color: black;
}