/* .Rectangle-19 {
    width: 330px;
    height: 652px;
    border-radius: 6px;
    border: solid 1px #324755;
    background-color: #ffffff;
  } */
.OrderFilterInputMaid {
    width: 100%; 
    height: 36px;
    border-radius: 5px;
    border: 1px solid rgb(112, 112, 112); 
    padding-left: 5px;
  }

.OrderFilterInput {
  width: 200px; 
  height: 36px;
  border-radius: 5px;
  border: 1px solid rgb(112, 112, 112); 
  padding-left: 5px;
}

.OderReactTable {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid rgb(50, 71, 85);
  background-color: rgb(255, 255, 255);
}


.cradProfileBooking{
  width: 290px !important;
  height: 100%!important;
  border-radius : 6px!important;
  border:  solid 1px #324755!important;
  background-color: #ffffff!important
}

@media screen and (max-width: 1680px) {
  .OrderFilterInput {
    width: 180px; 
  }
  .OderReactTable {
    width: 100%;
  }
}
@media screen and (max-width: 1400px) {
  .OrderFilterInput {
    width: 160px; 
  }
  .OderReactTable {
    width: 100%;
  }
}
@media screen and (max-width: 1250px) {
  .OrderFilterInput {
    width: 150px; 
  }
  .OderReactTable {
    width: 100%;
  }

}
@media screen and (max-width: 1000px) {
  .OrderFilterInput {
    width: 130px; 
  }
  .OderReactTable {
    width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .OrderFilterInput {
    width: 100px; 
  }
  .OderReactTable {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .OrderFilterInput {
    width: 100%; 
  }
  .OderReactTable {
    width: 100%;
  }
  .cradProfileBooking{
    height: 90vh!important;
  }
}
.bookingprofiletable .rt-tbody {
  min-height: 761px;
  max-height: 761px;
}

@media screen and (max-width: 1366px) and (max-height: 768px)  {

   .bookingprofiletable .rt-tbody {
    min-height: 450px;
    max-height: 450px;
    min-width: 1900px;
  }
}

@media screen and (max-width: 1200px) and (max-height: 768px)  {
  .bookingprofiletable .rt-tbody {
    min-height: 650px;
    max-height: 650px;
  }
}

@media screen and (max-width: 1366px) and (max-height: 500px)  {
  .bookingprofiletable .rt-tbody {
    min-height: 555px;
    max-height: 555px;
  }
}