.settingGroup-input {
    width: 757.9px;
    height: 51px;
    border-radius: 2px;
    border: solid 1px #324755;
    background-color: #ffffff;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    /* letter-spacing: normal; */
    text-align: left;
    color: #324755;
  }