.Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
  }
  
  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    padding: 5px 10px;
  }
  
  .Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }
  
  .Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
  }
  .rt-tbody {
    /* height:100vh; */
    justify-content: flex-start;
    flex: 1 1 auto;
    position: relative; 
    overflow-y: auto;
}

@media screen and (max-height: 768px) {
  .rt-tbody{
    height: 50vh;
  }
}

