.customerLsittetable .rt-tbody {
    min-height:718px;
    max-height: 718px;
  }

@media screen and (max-width: 1366px) and (max-height: 768px)  {
    .customerLsittetable .rt-tbody {
        min-height: 413px;
        max-height: 413px;
      }
   }