.ReactTable {
  flex:1;
  border:none;
  width:100%;
}


.ReactTable .-header {
  border-bottom: 1px solid #324755;
  flex: 1;
}

.ReactTable .rt-thead {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
}

.ReactTable .rt-tbody {
  align-content: start;
  flex: 0 1 auto;
}


.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border: none;
  text-align: start;
}

.ReactTable .rt-tbody .rt-th,
.ReactTable .rt-tbody .rt-td {
  border: none;

}

.ReactTable .rt-tbody .rt-tr-group{
  border: none;
}
.ReactTable .rt-tbody .rt-tr-group .rt-td{
  align-self: center;
}


.ReactTable .rt-thead .rt-th.-sort-asc,.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-sort-desc,.ReactTable .rt-thead .rt-td.-sort-desc{
  box-shadow: none;
}

.-sort-desc::after {
  content: " \25BC";
  float: right;
  position: absolute;
  top: 3px;
  right: 20px;
  color: #3e5569;

}

.-sort-asc::after {
  content: " \25B2";
  float: right;
  position: absolute;
  top: 3px;
  right: 20px;
  color: #3e5569;

}
