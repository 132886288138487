.Rectangle-710-broadtem {
    width: 725px;
    height: auto;
    border-radius: 6px;
    border: solid 1px #324755;
    background-color: #ffffff;
  }
  .Rectangle-738-broadtem {
    width: 687.3px;
    height: 51px;
    border-radius: 2px;
    border: solid 1px #324755;
    background-color: #ffffff;
    margin-top: 20px;
    margin-bottom: 5px;
    margin-left: 18px;
  }
  .Rectangle-22-broadtem {
    width: 687.3px;
    height: 51px;
    border-radius: 2px;
    border: solid 1px #324755;
    background-color: #ffffff;
    margin-bottom: 10px;
    margin-left: 18px;
  }
  .Rectangle-726-broadtem {
    width: 166.9px;
    height: 27.1px;
    border-radius: 5px;
    border: solid 1px #324755;
    background-color: #ffffff;
  }
  .Rectangle-659-broadtem {
    width: 687.3px;
    height: 208px;
    border-radius: 2px;
    border: solid 1px #324755;
    background-color: #ffffff;
    margin-left: 18px;
  }
  .Rectangle-731-broadtem {
    width: 215px;
    height: 200px;
    border-radius: 2px;
    border: solid 1px #324755;
    background-color: #ffffff;
  }
  .Rectangle-739-broadtem {
    width: 687px;
    height: 60px;
    border-radius: 2px;
    border: solid 1px #324755;
    background-color: #ffffff;
    margin-left: 18px;
  }

  .Rectangle-31-AnnounceTemp {
    width: 300px;
    height: 34px;
    border-radius: 1px;
    /* border: solid 1px #324755; */
    border-color: rgb(85, 22, 22);
    background-color: #ffffff;
  }

  /* ////////////////////// */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: #2196F3;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  /* /////////////////////// */


  /* ///////// RightBar //////////////// */
#RightBar-Broadcast{
  background: #6e8ca0;
  background-color: #6e8ca0;
  position: fixed;
  height: 100%;
  width: 249.7px;
  top: 40px;
  right: 0;
  /* z-index: 100; */
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  transition: 0.3s ease-in;
}
.mainPage-Template-Broadcast{
  position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


  /* //////////////////////////////// */
  .btn-button-broadcast {
    /* font-family: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif';  */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
   font-weight: 300;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    padding: 10px 16px;
    color: #ffffff;
    cursor: pointer;
    width: 140px;
    height: 40px;
    border-radius: 11px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
    background-color: #6e8ca0;
  }
  
  /* Style the active class (and buttons on mouse-over) */
  .active-button-broadcast{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    padding: 10px 16px;
    color: #ffffff;
    width: 140px;
    height: 40px;
    border-radius: 11px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
    border: solid 3px #6e8ca0;
    background-color: #324755;
  }

  /*///pageAnnoun///// */

  @media  only screen and (min-width: 1600px) {
  .pageAnnoun{
       padding-left: 250px !important;
    }
  }
  .pageAnnoun{
    padding-left: 120px ;
 }

 /* ///////////////////// */